<template>
	<h2 style="margin: 0 auto; text-align: center;margin-top: 4%;color:#409EFF ;" >银行卡号在线生成</h2>
		<el-row style="margin-top: 4%;">
			<el-col :sm="11" style="margin: 0 auto; text-align: center;">
				<el-form :model="bankCardData">
					<el-form-item label="生成数量">
							<el-col :sm = "24">
								<el-input v-model.number="bankCardData.size" placeholder="请输入1-1000范围整数"></el-input>
							</el-col>
					</el-form-item>
					
					<el-form-item label="">
						<el-col :sm = "4"></el-col>
						<el-input readonly  type="textarea" :rows="bankCardData.rows" resize='none'  v-model="bankCardData.text"></el-input>
					</el-form-item>
					
					<div style="margin: 0 auto; text-align: center;">
						<el-button  type="primary" @click="generate()">生成</el-button>
						<el-button  @click="copy(bankCardData.text)" type="primary" plain>复制</el-button>
					</div>
				</el-form>
		</el-col>
	</el-row>
</template>

<script setup>
import {ref} from 'vue'
import {generate as bancardGenerate} from './bankcard'
import {numberule as checknumber} from '../../common/validate'
import {moduleEvent as moduleaction} from '../../common/actionrecord'
import {clipboard as copy} from '../../common/actionrecord'	

    let bankCardData = ref({
		moduleid: 1006,
        size: 10,
        rows: 10,
        text: ""
    })
    let generate = () => {
		//校验代码
		var validresult = checknumber(bankCardData.value.size,1000);
		if(!validresult.success){
			bankCardData.value.text = validresult.info;
			return;
		}
		bankCardData.value.text = bancardGenerate(parseInt(bankCardData.value.size)).join("\n");
		moduleaction(bankCardData.value.moduleid,1);
    }
//进入页面，触发数据生成逻辑
generate();
</script>

<style>

</style>